import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import GlobalStyles from './assets/styles/global';


const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/' element={<Navigate replace to='/' />} />
      </Routes>
    </>
  );
}

export default App;
