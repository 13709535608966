import React, { useEffect, useRef, useState } from "react";
import { TCardEvent } from "./types";
import {
  CardContextProject,
  CardContextTop,
  StyledFAIcon,
  TitleCard,
} from "../Implement/styled";
import { Text, LinkProject } from "../Styled";
import { ReactComponent as IconDate } from "../../assets/images/date-icon.svg";
import { ReactComponent as LeftIconArrow } from "../../assets/images/arrow-calendar-left.svg";
import { ReactComponent as RightIconArrow } from "../../assets/images/arrow-calendar-right.svg";
import {
  ButtonChange,
  CardImageEvent,
  ImageEvent,
  StyledEventItemSwiper,
  StyledEventSwiper,
  SwiperContainer,
  WrapperNavigationSwiper,
} from "./styled";
import { Divider } from "../common/Divider";
import { useTranslation } from "react-i18next";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Swiper as SwiperType, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import { ModalImage } from "./ModalImage";
SwiperCore.use([Navigation]);

export const ListEvent: React.FC = () => {
  const [swipe, setSwipe] = useState<any>();
  const [dateSlide, setDateSlide] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const elementRef = React.createRef<HTMLParagraphElement>();
  const { t } = useTranslation();

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleClickPrev = () => {
    swipe?.slidePrev();
    setShowModal(false)
  };
  const handleClickNext = () => {
    swipe?.slideNext();
    setShowModal(false)
  };
  const getContent = (largeImageURL: string) => {
    setSelectedImage(largeImageURL);
    toggleModal();
  };
  useEffect(() => {
    getContent;
  }, []);

  const items = [
    {
      id: "1",
      date: "28.12.2022",
      imgUrl: "event1.png",
      largeImageURL: "slide/slide1.jpeg",
      title: t("event-card-title1"),
      text: t("event-card-text1"),
      linkProject:
        "https://richmanhouse.com/tpost/0uj3y2h8v1-world-charity-forum-charity-christmas-di",
    },
    {
      id: "2",
      date: "08.02.2023",
      imgUrl: "event3.png",
      largeImageURL: "slide/slide2.jpeg",
      title: t("event-card-project-title3"),
      text: t("event-card-project-text3"),
      linkProject:
        "https://richmanhouse.com/tpost/m31fi0z901-press-conference-with-owner-of-worlds-la",
    },
    {
      id: "3",
      date: "10.02.2023",
      imgUrl: "event.png",
      largeImageURL: "slide/slide3.jpeg",
      title: t("event-card-title"),
      text: t("event-card-text"),
      linkProject:
        "https://richmanhouse.com/tpost/5hm7kysf41-invest-pavilion-dubai-event-at-the-richm",
    },
    {
      id: "4",
      date: "18.02.2023",
      imgUrl: "event4.png",
      largeImageURL: "slide/slide4.jpeg",
      title: t("event-card-project-title2"),
      text: t("event-card-project-text2"),
      linkProject:
        "https://richmanhouse.com/tpost/7r7j8lsor1-richman-house-x-ukrainian-business-way-x",
    },
  ];

  return (
    <StyledEventSwiper>
      <SwiperContainer
        onBeforeInit={(swipper) => setSwipe(swipper)}
        initialSlide={3}
        allowTouchMove={false}
        onSlideChange={(swiper) => {
          const activeIndex = swiper.realIndex;
          const dates = items.map((item) => item.date);
          const dateSlide = dates[activeIndex];
          setDateSlide(dateSlide);
        }}
      >
        {items.map((item, index) => {
          const {
            id,
            date,
            title,
            text,
            linkProject,
            imgUrl,
            largeImageURL,
          } = item;
          return (
            <SwiperSlide key={id} virtualIndex={index}>
              <StyledEventItemSwiper>
                <CardContextProject>
                  <CardContextTop>
                    <TitleCard>{title}</TitleCard>
                    <StyledFAIcon>
                      <IconDate />
                      <Text ref={elementRef}>{date}</Text>
                    </StyledFAIcon>
                  </CardContextTop>
                  <Text>{text} </Text>
                  <LinkProject
                    className="secondary"
                    href={linkProject}
                    target="_blank"
                    aria-label="link project"
                    marginTop="30px"
                  >
                    {t("view-project")}
                  </LinkProject>
                </CardContextProject>
                <CardImageEvent>
                  <ImageEvent
                    src={require(`../../assets/images/${imgUrl}`)}
                    alt={title}
                    onClick={() => getContent(largeImageURL)}
                  />
                  {showModal && (
                    <ModalImage
                      onClose={toggleModal}
                      largeImageURL={selectedImage}
                      showModal={showModal}
                    />
                  )}
                </CardImageEvent>
              </StyledEventItemSwiper>
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
      <Divider height={30} />
      <WrapperNavigationSwiper>
        <ButtonChange name="prev" onClick={handleClickPrev}>
          <LeftIconArrow />
        </ButtonChange>
        <Text>{dateSlide}</Text>
        <ButtonChange name="next" onClick={handleClickNext}>
          <RightIconArrow />
        </ButtonChange>
      </WrapperNavigationSwiper>
    </StyledEventSwiper>
  );
};
