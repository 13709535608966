import React, { createContext, useState } from 'react';
import { Container, FlexWrapper } from '../Styled'
import { HeaderWrapper, LogoIcon, NavLinkWrapper, StyledContacts, ButtonMenu, FlexWrapperNavigation, BackdropMenuMobWrapper } from './styled'
import { useWindowDimensions } from '../../hooks'
import { MenuLink } from './MenuLink';
import { MenuList } from './MenuList';
import { SocialListLink } from '../Footer';
import { MenuLinkPages } from './MenuLinkPages';
import { ContactsWrapper } from './ContactsWrapper';
import { SelectLanguage } from './SelectLanguage';
import { ReactComponent as IconBurger } from '../../assets/images/hamburger.svg'
import { MenuLinkAthorization } from './MenuLinkAthorization';
import Logo from '../../assets/images/logo-heder.png'


export const MenuContext = createContext({
  isMenuOpen: true,
  toggleMenu: () => ({}),
});
export const Header: React.FC = () => {
  const [isMenuOpen, toggleMenu] = useState<boolean>(false);
  const size = useWindowDimensions().width;

  function toggleMenuMode() {
    toggleMenu(!isMenuOpen);
  }
  const HandleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    document.body.classList.toggle('hidden');
    toggleMenu(!isMenuOpen);
  };
  const handleLink = () => {
    document.body.classList.remove('hidden');
    toggleMenu(!isMenuOpen);
  };
  return (
    <HeaderWrapper>
      <Container>
        <FlexWrapper justify="space-between">
          <NavLinkWrapper
            to="/"
            aria-label="logo"
            onClick={e => {
              e.preventDefault();
              window.location.replace('/');
            }}
          >
            <LogoIcon src={Logo} alt="logo"/>
          </NavLinkWrapper>
          <MenuContext.Provider value={(isMenuOpen as boolean, toggleMenuMode as any)}>
            <BackdropMenuMobWrapper className={isMenuOpen ? 'is-open' : ''}>
            <MenuLink className={isMenuOpen ? 'is-open' : ''}>
              <FlexWrapperNavigation>
                <MenuList  handleLink={handleLink} />
                {/* <MenuLinkPages /> */}
              </FlexWrapperNavigation>
              {isMenuOpen && size <= 1199 && (
                <StyledContacts>
                  {/* <MenuLinkAthorization /> */}
                  <ContactsWrapper />
                  <SocialListLink />
                </StyledContacts>
              )}
              </MenuLink>
            </BackdropMenuMobWrapper>
            <SelectLanguage />
            <ButtonMenu type="button" onClick={HandleClickMenu}>
               <IconBurger />
            </ButtonMenu>
          </MenuContext.Provider>
          {/* {size > 1199 && (<MenuLinkAthorization />)} */}
        </FlexWrapper>
      </Container>
    </HeaderWrapper >
  )
}

