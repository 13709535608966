import { Formik } from "formik";
import React  from "react";
import { TFormProps } from "../../../utils/types";
import { Title3 } from "../../Styled";
import { InputField } from "../Input/InputField";
import { InputBoxWrapper, FormWrapper, ButtonForm } from "./styled";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import axios from "axios";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const initialValues: TFormProps = {
  name: "",
  company: "",
  phone: "",
  email: "",
};

export type TClassFormProps = {
  className: string;
  type: string;
};
export const Form: React.FC<TClassFormProps> = ({ className, type }) => {
  const { t } = useTranslation();
    const ContactSchema = Yup.object({
        name: Yup.string()
            .min(2)
            .required(t("field-required")),
        company: Yup.string()
            .min(2)
            .required(t("field-required")),
        phone: Yup.string()
            .required(t("field-required"))
            .matches(phoneRegExp, t("invalid-phone"))
            .min(5)
            .max(20),
        email: Yup.string().email(t("invalid-email")).required(t("field-required")),

    });
  const onSubmitData = (values: any, { resetForm }: { resetForm: any }) => {
    const data = {
      name: values.name,
      company: values.company,
      phone: values.phone,
      email: values.email,
      type: `${type}`,
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("company", data.company);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("type", data.type);

    axios({
      method: 'post',
      url: 'https://richhouseinvest.com/php/mail.php',
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      data: formData,
    })
      .then((response) =>{
        console.log(response.data);
        return response.data
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
      
    resetForm({ values: initialValues });
  };

  return (
    <>
      <Title3
        textAlign="left"
        color={className == "application" ? "black" : "white"}
      >
        {t("contact-info")}
      </Title3>
      <Formik
        initialValues={initialValues}
        validationSchema={ContactSchema}
        onSubmit={onSubmitData}
      >
        {({ values, handleChange, handleSubmit }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <InputBoxWrapper>
              <InputField
                type="text"
                name="name"
                label={t("name")}
                onChange={handleChange}
                value={values.name}
                className={className}
              />
              <InputField
                type="text"
                name="company"
                label={t("company")}
                onChange={handleChange}
                value={values.company}
                className={className}
              />
              <InputField
                type="tel"
                name="phone"
                label={t("phone")}
                onChange={handleChange}
                value={values.phone}
                className={className}
              />
              <InputField
                type="email"
                name="email"
                label="Email"
                onChange={handleChange}
                value={values.email}
                className={className}
              />
            </InputBoxWrapper>
            <ButtonForm
              type="submit"
              name="primary"
              title={type === "Membership Application" ? `${t("application-btn")}`:`${type}`}
              width="261px"
              height="35px"
            />
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
