import React from 'react';
import { FlexWrapper,Title4 } from '../Styled';
import { LinkContacts } from './styled';
import { useTranslation } from 'react-i18next';


export const ContactsWrapper: React.FC = () => {
    const { t } = useTranslation();
    return (
        <FlexWrapper direction='column' align='flex-start' gap='20px' >
            <Title4>{t('contacts')}</Title4>
            <LinkContacts href="tel:+971502151991" target="_blank" aria-label='tel'>+971502151991</LinkContacts>
            <LinkContacts href="mailto:rh@richmanhouse.com" target="_blank" aria-label='email'>rh@richmanhouse.com</LinkContacts>
        </FlexWrapper>
    )
}