import styled, { css } from "styled-components";
import { FLEX, FONT } from "../../utils";

export const DatePickerWrapper = styled.div`
  width: 351px;
  height: auto;
  padding: 33px 29px 27px 31px;
  background: #fffaef;
  @media screen and (min-width: 786px) {
    width: 365px;
    height: 378px;
  }
`;
export const DatePickerSelectorWrapper = styled.div`
  ${FLEX({ justify: "space-between" })}
  height: 30px;
  margin-bottom: 30px;
`;
export const DatePickerArrow = styled.button`
  display: inline-flex;
  ${FLEX({})};
  width: 30px;
  height: 30px;
  border: none;
  stroke: #000000;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: #e0e0e0;
  }
`;
export const DatePickerSelectorDate = styled.span`
  display: inline-flex;
  ${FONT({ weight: "200", size: "24px" })};
  line-height: 30px;
`;
export const DatePickerCalendarHeader = styled.div`
  ${FLEX({ justify: "space-between" })}
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
`;
export const DatePickerSelectorDateWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 30px;
`;
export const DatePickerCalendarCell = styled.div`
  width: 21px;
  height: 21px;
  margin: 0 2px;
  ${FONT({ weight: "200", size: "14px" })}
  line-height: 18px;
`;
export const DatePickerCalendarRow = styled.div`
  ${FLEX({ justify: "space-between" })};
  margin-bottom: 25px;
`;

export const Day = styled.div<{
  isToday?: boolean;
  isSelected?: boolean;
}>`
  ${FLEX({})};
  width: 21px;
  height: 21px;
  ${FONT({ weight: "200", size: "14px" })};
  line-height: 18px;
  border-radius: 50%;
  cursor: pointer;
  ${(props) =>
    props.isToday &&
    css`
      border: 1px solid #ebaf16;
    `}

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #ebaf16;
    `}
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 20px;
`;
