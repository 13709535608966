import React from 'react';
import { useField } from 'formik';
import { Input } from './Input';
import { TInputProps } from './types';

type InputFieldProps = Omit<TInputProps, 'isInvalid' | 'errorText'>;

export const InputField: React.FC<InputFieldProps> = ({ name, ...rest }) => {
    const [field, meta] = useField(name);
    return (
        <Input
            {...field}
            {...rest}
            isInvalid={Boolean(meta.error && meta.touched)}
            errorText={String(meta.error)}
        />
    );
};
