import React from 'react'
import { Divider } from '../common/Divider'
import { Container, SectionAdd, SpanGold, Title2 } from '../Styled'
import { ContactForm } from './ContactForm'

import { ListInvest } from './ListInvest';
import { NumberListInvest } from './NumberListInvest';
import { useTranslation } from 'react-i18next';


export const Investment: React.FC = () => {
    const { t } = useTranslation();
    return (
        <SectionAdd id="projects">
            <Container>
                <Title2 textAlign='center'>{t('investor-title-one')}<SpanGold> {t('investor-title-gold')} </SpanGold></Title2>
                <ContactForm/>
                <Divider height={70} />
                <Title2 textAlign='center'>{t('proposition-dark')}<SpanGold> {t('proposition-gold')} </SpanGold></Title2>
                <ListInvest />
                <NumberListInvest/>
            </Container>
        </SectionAdd>
    )
}