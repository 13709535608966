import React from "react";
import { TPropsSize } from "../../utils/types";
import { Container, SectionAdd } from "../Styled";
import { Slider } from "./Slider";
import { SwiperContainer } from "./styled";
import { SwiperList } from "./SwiperList";

export const Application: React.FC<TPropsSize> = ({ size }) => {
  return (
    <SectionAdd >
      <Container>
        <Slider/>
        <SwiperContainer id="application">
          <SwiperList size={size} />
        </SwiperContainer>
      </Container>
    </SectionAdd>
  );
};
