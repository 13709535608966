import styled from "styled-components";
import { Field } from "formik";
import { COLORS } from "../../../assets/colors";
import { FONT } from "../../../utils/css";

export const StyledInputWrapper = styled.div`
  position: relative;
  @media screen and (min-width: 1200px) {
    width: 100%;
    margin: 0 auto;
  }
`;
export const Label = styled.label<{ className: string }>`
  ${FONT({ weight: "200", size: "14px" })}
  line-height: 15px;
  color: ${(props) =>
    props.className === "contact" ? `${COLORS.black}` : "#9E9E9E"};
  position: absolute;
  transform: translateY(2rem);
  top: -22px;
  left: 21px;
  transform-origin: left top;
  cursor: text;
  transition: all 0.3s;
  pointer-events: none;
  border: 1px solid transparent;
`;

export const StyledInput = styled(Field)<{ className: string }>`
width:100%;
  height: 35px;
  padding: 10px 21px 0;
  ${FONT({ weight: "200", size: "14px" })}
  line-height: 15px;
  color:${COLORS.black};
  border: ${(props) =>
    props.className === "contact"
      ? "1px solid transparent"
      : "1px solid #EBAF16"};
  border-radius: 6px;
  outline: none;
  background: ${(props) =>
    props.className === "contact" ? `${COLORS.white}` : `transparent`};
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* &:focus,
  &:not(:placeholder-shown) {
    border-color: ${(props) =>
      props.className === "contact" ? `#EBAF16` : `${COLORS.white}`};
  } */

  &::placeholder {
    color: transparent;
  }
  &:focus,
  &::not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    outline: none;
  }
  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    ${FONT({ size: "12px" })}
    padding: 0;
    cursor: text;
    color: #9E9E9E;
    transform: translateY(20px);
  }
  @media screen and (max-width: 1199px) {
width: 311px;
  }
`;

export const Error = styled.span`
  position: absolute;
  top: 38px;
  left: 10px;
  width: 100%;
  height: 5px;
  ${FONT({
    size: "14px",
    weight: "200",
    color: `${COLORS.red}`,
  })}
`;

export const LabelTextarea = styled(Label)`
  position: absolute;
  transform: translateY(2rem);
  top: -18px;
  left: 20px;
`;
export const StyledTextarea = styled(Field)`
  width: 100%;
  max-height: 120px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  padding: 20px;
  ${FONT({ weight: "200", size: "14px" })}
  line-height: 15px;
  color: ${COLORS.black};
  border: ${(props) =>
    props.className === "contact"
      ? "1px solid transparent"
      : "1px solid #EBAF16"};
  border-radius: 6px;
  outline: none;
  background: ${(props) =>
    props.className === "contact" ? `${COLORS.white}` : `transparent`};
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: inherit;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: transparent;
  }
  &:focus,
  &::not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  &:focus + ${Label}, &:not(:placeholder-shown) + ${LabelTextarea} {
    ${FONT({ size: "12px" })}
    padding: 0;
    cursor: text;
    color: #9e9e9e;
    transform: translateY(20px);
  }

  @media screen and (max-width: 1199px) {
    width: 311px;
  }
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
`;
