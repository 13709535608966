import React from 'react';
import {  LabelTextarea, StyledInputWrapper, StyledTextarea } from './styled';
import { TTextareaProps } from './types';

export const TextArea:React.FC<TTextareaProps> = ({
    name,
    label,
    error,
    isValid,
    value,
    className,
    ...props
}) => {
    return (
        <StyledInputWrapper>
            <StyledTextarea
                autoComplete="off"
                name={name}
                value={value}
                className="contact"
                placeholder=" "
                component="textarea"
                rows="6"
                {...props}
            />
            {label && (
                <LabelTextarea className="contact" htmlFor={name}>
                    {label}
                </LabelTextarea>
            )}
            {/* <Error>{!isValid ? error : ''}</Error> */}
        </StyledInputWrapper>
    );
};
