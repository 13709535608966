import React from 'react';
import { StyledMenuLink } from './styled';
import { TMenuLink } from './types';

export const MenuLink: React.FC<TMenuLink> = ({ className, children }) => {
    return (
        <>
            <StyledMenuLink className={className}>{children}</StyledMenuLink>
        </>
    );
};