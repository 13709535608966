import React from 'react';

import { FlexWrapper } from '../Styled';
import { LinkItem, SocialIcon, SocialIconTelegram } from './styled';

import telegram from '../../assets/images/telegram.png'
import facebook from '../../assets/images/facebook.png'
import instagram from '../../assets/images/instagram.png'

export const SocialListLink: React.FC = () => {
    return (
        <FlexWrapper gap="46px">
            <LinkItem href="https://t.me/+k2CHnbwHnm5kODYy" target="_blanc" >
                <SocialIconTelegram src={telegram} alt="telegram" />
            </LinkItem>
            <LinkItem href="#" >
                <SocialIcon src={facebook} alt="facebook" />
            </LinkItem>
            <LinkItem href="#" >
                <SocialIcon src={instagram} alt="instagram" />
            </LinkItem>
        </FlexWrapper>
    );
};
