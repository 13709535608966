import styled from "styled-components";
import { FLEX } from "../../utils";
import bgModal from "../../assets/images/bg-modal.png";
import { Title3 } from "../Styled";
import { Button } from "../common/Button";

export const ModalButton = styled.button`
  position: absolute;
  ${FLEX({})};
  top: 23px;
  right: 13px;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background: transparent;
`;
export const CloseIcon = styled.img`
  width: 100%;
`;
export const ModalContent = styled.div<{ className: string }>`
  position: absolute;
  z-index: 1001;
  margin:0 auto;
  width: 375px;
  height: 409px;
  padding: 30px;
  background:#FFFAEF;
  background-image:url('${bgModal}');
  opacity:0;
  scale: 0;
  transform:none;
  transition: all 1000ms linear;
  @media screen and (max-width: 1199px) {
     animation:rotate 0.3s linear 1;
 @keyframes rotate {
    from { transform: rotateY(90deg); }
    to {transform: rotateY(0deg);  }
 }
  }
 
  @media screen and (min-width: 1200px) {
    width: 1004px;
    height: 255px;
        animation:rotate 0.3s linear 1;
 @keyframes rotate {
    from { transform: rotateX(90deg); }
    to {transform: rotateY(0deg);  }
 }
  }
  &.active{
  opacity:1;
  scale:1;
  transition: all 0.3s linear;
 
  }
`;

export const TitleForm = styled(Title3)`
  margin-bottom: 0;
`;

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;
export const InputBoxWraper = styled.div`
  ${FLEX({ direction: "column" })};
  margin-bottom: 20px;
  gap: 20px;

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }
`;
export const ButtonSubmit = styled(Button)`
  &:hover,
  &:focus {
    background: transparent;
  }
  @media screen and (max-width: 1199px) {
    max-width: 100%;
  }
  @media screen and (min-width: 1200px) {
    margin-left: auto;
  }
`;
