import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { CardItemImage, ChangeArrowSlider, SliderContainer, SwiperWrapper } from "./styled";

import { ReactComponent as LeftIconArrow } from "../../assets/images/arrow-calendar-left.svg";
import { ReactComponent as RightIconArrow } from "../../assets/images/arrow-calendar-right.svg";
import { Image, Title2 } from "../Styled";

SwiperCore.use([Navigation]);
export const Slider: React.FC = () => {
  const { t } = useTranslation();
  const [swipe, setSwipe] = useState<any>();
  const slides = [
    {
      id: "1",
      imgUrl: "slide1.jpg",
    },
    {
      id: "2",
      imgUrl: "slide2.jpeg",
    },
    {
      id: "3",
      imgUrl: "slide3.jpg",
    },
    {
      id: "4",
      imgUrl: "slide4.jpeg",
    },
    {
      id: "5",
      imgUrl: "slide5.jpg",
    },
    {
      id: "6",
      imgUrl: "slide6.jpeg",
    },
    {
      id: "7",
      imgUrl: "slide9.jpeg",
    },
  ];

  return (
    <>
      <Title2 textAlign="center">{t("title-slider")}</Title2>
      <SliderContainer>
        <SwiperWrapper
          onBeforeInit={(swipper) => setSwipe(swipper)}
        
          centeredSlides={false}
          centerInsufficientSlides={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={slide.id} virtualIndex={index}>
              <CardItemImage>
                <Image
                  src={require(`../../assets/images/slide/${slide.imgUrl}`)}
                  alt="slide image"
                />
              </CardItemImage>
            </SwiperSlide>
          ))}
        </SwiperWrapper>

        <ChangeArrowSlider name="prev" onClick={() => swipe?.slidePrev()}>
          <LeftIconArrow />
        </ChangeArrowSlider>
        <ChangeArrowSlider name="next" onClick={() => swipe?.slideNext()}>
          <RightIconArrow />
        </ChangeArrowSlider>
      </SliderContainer>
    </>
  );
};
