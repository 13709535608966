import styled from "styled-components";
import { FONT, FLEX } from "../../../utils/css";

export const Lable = styled.span<{
  name: string;
}>`
  ${FLEX({ align: "center" })};
  ${FONT({ weight: "400", size: "14px" })};
  color: ${(props) => (props.name === "primary" ? "white" : "#EBAF16")};
  line-height: 18px;
  text-align: center;
  transition: 0.3s linear;
`;

export const StyledButton = styled.button<{
  name: string;
  width: string;
  height: string;
  maxWidth?: string;
}>`
  width: 100%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  flex-shrink: 1;
  border: ${(props) =>
    props.name === "primary" ? "1px solid transparent" : "1px solid #EBAF16"};
  background: ${(props) =>
    props.name === "primary" ? "#EBAF16" : "transparent"};
  transition: 0.3s linear;

  &:hover,
  &:focus {
    background: ${(props) => (props.name === "primary" ? "white" : "#EBAF16")};
    border: ${(props) =>
    props.name === "primary" ? "1px solid #EBAF16" : "1px solid transparent"  };
  }
  &:hover ${Lable},
  &:focus ${Lable}{
    color: ${(props) => (props.name === "primary" ? "#EBAF16" : "white")};
  }
  @media screen and (max-width: 1199px) {
    display: inline-block;
    width: 100%;
    padding:0 33px;
    max-width: ${(props) => props.width};
    margin-left: auto;
    margin-right: auto;
  }
 
`;

export const StyledButtonScrollBack = styled.div`
  ${FLEX({})};
  position: absolute;
  bottom: 9%;
  right: 0;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  text-align: center;
  background: #ebaf16;
  cursor: pointer;
  z-index: 3;
  @media screen and (min-width: 1200px) {
    width: 60px;
    height: 60px;
    bottom: 6%;
    right: 0;
  }
`;
