import React from 'react'
import ReactPlayer from 'react-player'
import video from '../../assets/images/video.mp4'
import videoPost from "../../assets/images/newPoster.jpeg";
import videoPostMob from "../../assets/images/newPosterMob.jpg";
import { useWindowDimensions } from '../../hooks';


export const Video = () => {
    const size: number = useWindowDimensions().width;
    return (
        <>
            <ReactPlayer
                url={video}
                light={size <= 767 ? videoPostMob : videoPost}
                width={size <= 767 ? '351px' : '640px'}
                height={size <= 767 ? '240px' : '360px'}
                playing={true}
                controls={true}
            />
        </>
    )
}

