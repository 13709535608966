import React from "react";
import {
  CardContextProject,
  StyledFAIcon,
  TitleCard,
} from "../Implement/styled";
import { FlexWrapper, LinkProject, Text } from "../Styled";
import {
  AddressWrapper,
  AdressText,
  ButtonAplication,
  ButtonWrapper,
  CardContextTopEvent,
  ImageEventProject,
  StyledCardItemProject,
  StyledListEvent,
  CardItemImageEvent,
  TextAdd
} from "./styled";
import {  TCardEventProject } from "./types";
import { ReactComponent as IconDate } from "../../assets/images/date-icon.svg";
import { ReactComponent as IconAddress } from "../../assets/images/address-icon.svg";
import { useTranslation } from 'react-i18next';
import { TPropsSize } from "../../utils/types";

export const ListEventProject: React.FC<TPropsSize> = ({
  size
}) => {
  const { t } = useTranslation();
  const projects = [
    {
      id: "4",
      date: "26.01.2023",
      time: "12:30",
      address: "Grosvenor House - Al Emreef St",
      imgUrl: "proj1.png",
      imgUrlMob: "proj1.png",
      title: t("event-card-project-title1"),
      text: t("event-card-project-text1"),
      linkProject: "https://richmanhouse.com/tpost/salb3yo7o1-dubai-crypto-thursday-at-the-richman-hou",
    },
    {
      id: "5",
      date: "18.02.2023",
      time: "11:00",
      address: "Grosvenor House - Al Emreef St",
      imgUrl: "proj2.png",
      imgUrlMob: "proj2.png",
      title: t("event-card-project-title2"),
      text: t("event-card-project-text2"),
      linkProject: "https://richmanhouse.com/tpost/7r7j8lsor1-richman-house-x-ukrainian-business-way-x",
    },
    {
      id: "6",
      date: "08.02.2023",
      time: "11:00",
      address: "Grosvenor House - Al Emreef St",
      imgUrl: "proj3.png",
      imgUrlMob: "proj3.png",
      title: t("event-card-project-title3"),
      text: t("event-card-project-text3"),
      linkProject: "https://richmanhouse.com/tpost/m31fi0z901-press-conference-with-owner-of-worlds-la",
    },
  ]
  const handleClick = ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.location.href = '#application';
  })
  return (
    <StyledListEvent>
      {projects.map((project: TCardEventProject) => {
        const {
          id,
          date,
          time,
          address,
          imgUrl,
          imgUrlMob,
          title,
          text,
          linkProject,
        } = project;
        return (
          <StyledCardItemProject key={id}>
            <CardItemImageEvent>
              <ImageEventProject
                src={
                  size <= 767
                    ? require(`../../assets/images/${imgUrlMob}`)
                    : require(`../../assets/images/${imgUrl}`)
                }
                alt={title}
              />
            </CardItemImageEvent>
            <CardContextProject>
              <CardContextTopEvent>
                <TitleCard>{title}</TitleCard>
                <StyledFAIcon>
                  <IconDate />
                  <FlexWrapper>
                    <Text>{date} </Text>
                    <TextAdd>at </TextAdd>
                    <Text>{time}</Text>
                  </FlexWrapper>
                </StyledFAIcon>
              </CardContextTopEvent>
              <AddressWrapper>
                <IconAddress />
                <AdressText>{address}</AdressText>
              </AddressWrapper>
              <Text>{text} </Text>
              <ButtonWrapper>
                <ButtonAplication
                  type="button"
                  name="primary"
                  title={t('application-btn')}
                  maxWidth="100%"
                  width="261px"
                  height="35px"
                  onClick={handleClick}
                />
                <LinkProject
                  className="primary"
                  href={linkProject}
                  target="_blank"
                  aria-label="link project"
                >
                  {t('view-project')}
                </LinkProject>
              </ButtonWrapper>
            </CardContextProject>
          </StyledCardItemProject>
        );
      })}
    </StyledListEvent>
  );
};
