import { TMediaBreakpoints, TMediaQueries } from './types';

export const MediaQueries: TMediaQueries = {
  xs: '<375px',
  md: '>=375px',
};

export const MediaBreakpoints: TMediaBreakpoints = {
  md: 375,
};
