import React from "react";
import { useTranslation } from "react-i18next";
import { Option, SelectWrapper } from "./styled";

export const SelectLanguage: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (e: { target: HTMLSelectElement }) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <SelectWrapper
      id="select"
      name="select"
      value={i18n.language}
      onChange={changeLanguage}
    >
      <Option value="en">Eng</Option>
      <Option value="ru"> Rus</Option>
    </SelectWrapper>
  );
};
