import styled from "styled-components";
import { Section } from "../Styled";
import BgLine from "../../assets/images/border-img.png";
import { FLEX } from "../../utils";

export const SectionMission = styled(Section)`
position:relative;
padding-bottom:70px;
&::after{
    position:absolute;
    display:block;
    content:" ";
    width:100%;
    height:50px;
    bottom:-50px;
    left:0;
    background-image:url('${BgLine}');
};
`;
export const FlexWrapperImage = styled.div`
  display: relative;
  width: 100%;
  ${FLEX({})};
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
  
`;

export const TextWrapperDivider = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px dashed rgba(149, 149, 149);
`;

