import React from "react";
import { TPropsSize } from "../../utils/types";
import { Divider } from "../common/Divider";
import { Container, FlexWrapper, SpanGold, Title2 } from "../Styled";
import { ContactFormInvestor } from "./ContactFormInvestor";
import { ListImplement } from "./ListImplement";
import { SectionImplement } from "./styled";
import { useTranslation } from "react-i18next";
import { Slider } from "./Slider";
import { CardItem } from "./CardItem";

export const Implement: React.FC<TPropsSize> = ({ size }) => {
  const { t } = useTranslation();
  return (
    <SectionImplement id="implement">
      <Container>
        {/* <Title2 textAlign="center">
          {t("implement-title")}
          <SpanGold> {t("implement-title-add")}</SpanGold>
        </Title2>
        <CardItem />
        <Divider height={40} />
        <Slider />
        <Divider height={40} />
        <ListImplement size={size} />
        <Divider height={70} />
        <Title2 textAlign="center">
          {t("project-title-form")}
          <SpanGold> {t("project-title-form-add")}</SpanGold>
        </Title2> */}
        
        <Title2 textAlign="center">{t("project-title-form")}<SpanGold>{t("project-title-form-add")}</SpanGold></Title2>
        <ContactFormInvestor />
      </Container>
    </SectionImplement>
  );
};
