import React from 'react';
import { StyledHashLink, StyledMenuList, NavigationListItem } from './styled';
import { TLink, TLinksProps } from './types';
import { useTranslation } from 'react-i18next';

export const MenuList: React.FC<TLinksProps> = ({ handleLink }) => {
    const { t } = useTranslation();

    return (
        <StyledMenuList>
            {(t('links', { returnObjects: true }) as TLink[]).map((link: TLink) => (
                <NavigationListItem key={link.id}>
                    <StyledHashLink
                        to={link.href}
                        activeClass='active'
                        spy={true}
                        smooth={true}
                        duration={500}
                        onClick={handleLink}
                    >
                        {link.title}
                    </StyledHashLink>
                </NavigationListItem>
            ))}
        </StyledMenuList>
    );
};

