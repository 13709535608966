import React from "react";
import {
  Container,
  FlexWrapper,
  Image,
  SpanGold,
  Text,
  Title2,
  Title3,
} from "../Styled";
import mission from "../../assets/images/img-mission.png";
import missionMob from "../../assets/images/img-mission-mob.png";
import investor from "../../assets/images/investors.jpg";
import {
  FlexWrapperImage,
  SectionMission,
} from "./styled";
import { Button } from "../common/Button";
import { Divider } from "../common/Divider";
import { TPropsSize } from "../../utils/types";
import { useTranslation } from "react-i18next";


export const Mission: React.FC<TPropsSize> = ({ size }) => {
  const { t } = useTranslation();
  const handleClickApplication= (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    window.location.href = "#implement";
  };
  const handleClickInvestor = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    window.location.href = "#projects";
  };
  return (
    <SectionMission id="mission" color="white">
      <Container>
        <Title2 textAlign="center">
          {t("title-mission-one")}{" "}
          <SpanGold> {t("title-mission-two")}</SpanGold>
          ?
        </Title2>
        <FlexWrapper gap="40px" direction={size <= 1199 ? "column" : "row"}>
          <FlexWrapperImage>
              <Image src={investor} alt="image investor" />
          </FlexWrapperImage>
          <FlexWrapper
            direction="column"
            align="flex-start"
            width={size <= 1199 ? " " : "50%"}
          >
            <Title3 textAlign="left">{t("inevestor")}</Title3>
            <Text size="16px" lineHeight="24px">
              {t("investor-text-add")}
            </Text>
            <Divider height={30} />
            <Button
              type="button"
              name="primary"
              title={t("investor-title")}
              width={size <= 1199 ? "350px" : "288px"}
              height="40px"
              onClick={handleClickInvestor}
            />
          </FlexWrapper>
        </FlexWrapper>
        <Divider height={40} />
        <FlexWrapper gap="40px" direction={size <= 1199 ? "column-reverse" : "row"}>
          <FlexWrapper
            direction="column"
            align="flex-start"
            width={size <= 1199 ? " " : "50%"}
          >
            <Title3 textAlign="left">{t("project")} </Title3>
            <Text size="16px" lineHeight="24px">
              {t("project-text")}{" "}
            </Text>
            <Divider height={30} />
            <Button
              type="button"
              name="primary"
              title={t("project-form")}
              width={size < 1200 ? "350px" : "288px"}
              height="40px"
              onClick={handleClickApplication}
            />
          </FlexWrapper>
          <FlexWrapperImage>
            <Image src={ mission} alt="image" />
          </FlexWrapperImage>
        </FlexWrapper>
      </Container>
    </SectionMission>
  );
};
