import React from "react";
import { Formik } from "formik";
import { Button } from "../common/Button";
import { InputField } from "../common/Input/InputField";
import { Title3 } from "../Styled";
import {
  ContactFormWrapper,
  FormContact,
  InputBoxWrapper,
  StyledContactForm,
} from "./styled";
import { TContactsProps } from "./types";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const initialValues: TContactsProps = {
  name: "",
  company: "",
  phone: "",
  email: "",
};

export const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const ContactSchema = Yup.object({
    name: Yup.string().min(2).required(t("field-required")),
    company: Yup.string().min(2).required(t("field-required")),
    phone: Yup.string()
      .required(t("field-required"))
      .matches(phoneRegExp, t("invalid-phone"))
      .min(5)
      .max(20),
    email: Yup.string().email(t("invalid-email")).required(t("field-required")),
  });
  const onSubmitData = (values: any, { resetForm }: { resetForm: any }) => {
    const data = {
      name: values.name,
      company: values.company,
      phone: values.phone,
      email: values.email,
      type: t('investor-title'),
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("company", data.company);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("type", data.type);

    axios({
      method: "post",
      url: "https://richhouseinvest.com/php/mail.php",
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      data: formData,
    })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
    resetForm({ values: initialValues });
  };

  return (
    <ContactFormWrapper>
      <Title3 textAlign="left" color="white">
        {t("contact-info")}
      </Title3>
      <StyledContactForm>
        <Formik
          initialValues={initialValues}
          validationSchema={ContactSchema}
          onSubmit={onSubmitData}
        >
          {({ values, handleChange, handleSubmit }) => (
            <FormContact onSubmit={handleSubmit}>
              <InputBoxWrapper>
                <InputField
                  type="text"
                  name="name"
                  label={t("name")}
                  onChange={handleChange}
                  value={values.name}
                  className="contact"
                />
                <InputField
                  type="text"
                  name="company"
                  label={t("company")}
                  onChange={handleChange}
                  value={values.company}
                  className="contact"
                />
                <InputField
                  type="tel"
                  name="phone"
                  label={t("phone")}
                  onChange={handleChange}
                  value={values.phone}
                  className="contact"
                />
                <InputField
                  type="email"
                  name="email"
                  label="Email"
                  onChange={handleChange}
                  value={values.email}
                  className="contact"
                />
              </InputBoxWrapper>
              <Button
                type="submit"
                name="primary"
                title={t("investor-title")}
                width="261px"
                height="35px"
              />
            </FormContact>
          )}
        </Formik>
      </StyledContactForm>
    </ContactFormWrapper>
  );
};
