import React from 'react';
import { Title3 } from '../Styled';
import { NumberItem, NumberWrapper, StyledNumberList } from './styled';
import { TNumber } from './types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';



export const NumberListInvest: React.FC = () => {
    const { t} = useTranslation();

    return (
        <>
            <StyledNumberList>
                {(t('numbers', { returnObjects: true }) as TNumber[]).map(({ number,title }) => (
                    <NumberItem key={uuidv4()}>
                        <NumberWrapper>
                         {number}
                        </NumberWrapper>
                        <Title3>{title}</Title3>
                    </NumberItem>
                ))}
            </StyledNumberList></>
    )
      
}