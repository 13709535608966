import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  FlexWrapperNavigation,
  NavigationFooterList,
  NavigationListItem,
  TitleNavigationWrapper,
} from "./styled";
import { NavLinkPage, StyledHashLink } from "../Header/styled";
import { useTranslation } from "react-i18next";
import { TLink } from "../Header/types";

export const FooterLinks: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FlexWrapperNavigation>
      {/* <TitleNavigationWrapper>RHI CLUB</TitleNavigationWrapper> */}
      <NavigationFooterList>
        {(t("links", { returnObjects: true }) as TLink[]).map((link: TLink) => (
          <NavigationListItem key={link.id}>
            <StyledHashLink
              to={link.href}
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              {link.title}
            </StyledHashLink>
          </NavigationListItem>
        ))}
        {/* <NavigationListItem key={uuidv4()}>
          <NavLinkPage to="#" aria-label="link" target="_blank">
            NFT
          </NavLinkPage>
        </NavigationListItem>
        <NavigationListItem key={uuidv4()}>
          <NavLinkPage to="#" aria-label="link" target="_blank">
            {t("wallet")}
          </NavLinkPage>
        </NavigationListItem> */}
      </NavigationFooterList>
    </FlexWrapperNavigation>
  );
};
