import styled from "styled-components";
import { FLEX, FONT } from "../../utils";
import BgMob from "../../assets/images/bg-hero-mob.png";
import Bg from "../../assets/images/bg-hero.png";
import NewBg from "../../assets/images/banner_1.png";
import NewBgBig from "../../assets/images/banner_1@2x.png";
import { COLORS } from "../../assets";


export const HeroWrapper = styled.section`
${FLEX({})};
  width: 100%;
  height: 100vh;
  padding-top:80px;
  background-image: url('${NewBg }');
  background-image: -webkit-image-set(url('${NewBg}') 1x,url('${NewBgBig}') 2x);
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;
  
`;
export const FlexWrapperHero = styled.div`
  ${FLEX({ direction: "column" })}
  gap: 30px;
  @media screen and (min-width: 1200px) {
    max-width:378px;
    margin:0 auto;
  }
`;

export const HeroTitle = styled.p`
${FONT({ weight: '400', size: '18px' })};
color:${COLORS.white };
text-align: center;
`
export const HeroText = styled.p`
${FONT({ weight: '200', size: '16px' })};
color:${COLORS.white};
text-align: center;
line-height:1.4;
`
