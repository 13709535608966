import React from "react";
import { useState, useEffect } from "react";
import {
  DatePickerArrow,
  DatePickerCalendarCell,
  DatePickerCalendarHeader,
  DatePickerSelectorDate,
  DatePickerSelectorDateWrapper,
  DatePickerWrapper,
  DateWrapper,
  Day,
} from "./styled";
import { ReactComponent as LeftIconArrow } from "../../assets/images/arrow-calendar-left.svg";
import { ReactComponent as RightIconArrow } from "../../assets/images/arrow-calendar-right.svg";

export const Calendar: React.FC = () => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const [date, setDate] = useState(today);
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  function getStartDayOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  }

  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;

  return (
    <DatePickerWrapper>
      <DatePickerCalendarHeader>
        <DatePickerArrow
          onClick={() => setDate(new Date(year, month - 1, day))}
        >
          <LeftIconArrow />
        </DatePickerArrow>
        <DatePickerSelectorDate>
          {MONTHS[month]} {year}
        </DatePickerSelectorDate>
        <DatePickerArrow
          onClick={() => setDate(new Date(year, month + 1, day))}
        >
          <RightIconArrow />
        </DatePickerArrow>
      </DatePickerCalendarHeader>
      <DatePickerSelectorDateWrapper>
        {DAYS_OF_THE_WEEK.map((d) => (
          <DatePickerCalendarCell key={d}>{d}</DatePickerCalendarCell>
        ))}
      </DatePickerSelectorDateWrapper>
      <DateWrapper>
        {Array(days[month] + (startDay - 1))
          .fill(null)
          .map((_, index) => {
            const d = index - (startDay - 2);
            return (
              <Day
                key={index}
                isToday={d === today.getDate()}
                isSelected={d === day}
                onClick={() => setDate(new Date(year, month, d))}
              >
                {d > 0 ? d : ""}
              </Day>
            );
          })}
      </DateWrapper>
    </DatePickerWrapper>
  );
};
