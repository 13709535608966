import styled from "styled-components";
import { FLEX } from "../../../utils";
import { Button } from "../Button";

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputBoxWrapper = styled.div`
  ${FLEX({ direction: "column" })};
  margin-bottom: 20px;
  gap: 20px;

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }
`;

export const ButtonForm = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  &:hover,
  &:focus {
    background: transparent;
  }
`;
