import React from "react";
import { useTranslation } from "react-i18next";
import { TPropsSize } from "../../utils/types";
import { Container, FlexWrapper, Image, Section, SpanGold, Text, Title2, Title3 } from "../Styled";
import { FlexWrapperImage } from "./styled";
import Founder from '../../assets/images/newPoster.jpeg'
import { Video } from "./PlayVideo";

export const ClubFounder: React.FC<TPropsSize> = ({ size }) => {
    const { t } = useTranslation();
    return (
      
    <Section color="white">
      <Container>
        <Title2 textAlign="center">
          {t("title-founder-one")}{" "}
          <SpanGold> {t("title-founder-gold")} </SpanGold>
                </Title2>
                <FlexWrapper gap="40px" direction={size <= 1199 ? "column" : "row"}>
                    <FlexWrapper
                        direction="column"
                        align="flex-start"
                        width={size <= 1199 ? " " : "50%"}
                    >
                        <Title3 textAlign="left">{t("club-title")} </Title3>
                        <Text size="16px" lineHeight="24px">
                            {t("club-content")}
                            <br/><br/>
                             {t("club-content-add")}
                        </Text>
                    </FlexWrapper>
                    <FlexWrapperImage>
              {size <= 1199 ? (<Image src={Founder} alt="image founder" />):(< Video />)}
                        
                    </FlexWrapperImage>
                </FlexWrapper>
      </Container>
    </Section>
  );
};
