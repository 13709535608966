import styled from "styled-components";

export const StyledBackdrop = styled.div<{ className: string }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  display: ${(props) => (props.className === "active" ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.className === "active" ? "rgba(0, 0, 0, 0.2)" : " "};
  visibility: ${(props) =>
    props.className === "active" ? "visible" : "hidden"};
  opacity: ${(props) => (props.className === "active" ? "1" : "0")};
  pointer-events: initial;
  overflow: hidden;
  transition: all 500ms linear;

  @media screen and (min-width: 1200px) {
    background: ${(props) =>
      props.className === "active" ? "rgba(0, 0, 0, 0.4)" : " "};
  }
`;
