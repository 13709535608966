import React from 'react'
import {Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Application } from '../../components/Application';
import { ClubFounder } from '../../components/ClubFouder/ClubFouder';
import { Events } from '../../components/Events/Evetns';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header/Header';
import { Hero } from '../../components/Hero';
import { Implement } from '../../components/Implement';
import { Investment } from '../../components/Investment/Investment';
import { Mission } from '../../components/Mission/Mission';
import { useWindowDimensions } from '../../hooks';



export const HomePage: React.FC = () => {
  const size:number = useWindowDimensions().width;
  return (
    <>
      <Header/>
      <Hero />
      <ClubFounder size={size} />
      <Element name="mission" >
        <Mission size={size} />
      </Element>
      <Element name="projects" >
        <Investment />
      </Element>
      <Element name="implement" >
        <Implement size={size} />
      </Element>
      <Element name="events">
        <Events size={size} />
      </Element>
      <Element name="application">
        <Application size={size} />
      </Element>
      <Footer size={size} />
    </>
  )
};

