import styled from "styled-components";
import { FLEX, FONT } from "../../utils";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../assets";
import { ReactComponent as Logo } from "../../assets/images/logo-footer.svg";

export const StyledIconList = styled.ul`
  ${FLEX({ justify: "center" })};
  @media screen and (max-width: 1199px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify: "space-between" })};
    margin-left: 148px;
  }
`;

export const LinkItem = styled.a`
  text-decoration: none;
  display: inline-flex;
  ${FLEX({ justify: "center" })};
  flex-shrink: 0;
  cursor: pointer;
  border: none;
  text-align: center;
  transition: all 0.5s linear;
`;

export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
`;
export const SocialIconTelegram = styled.img`
  width: 35px;
  height: 35px;
`;
export const ImageIcon = styled.img`
  width: 30px;
  height: 30px;
`;
export const StyledFooter = styled.footer`
  padding-top: 70px;
  padding-bottom: 70px;
  background: #191722;
  @media screen and (min-width: 1200px) {
    padding-bottom: 40px;
  }
`;
export const FooterWrapper = styled.div`
  ${FLEX({ direction: "column" })};
  gap: 40px;
  @media screen and (min-width: 1200px) {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const NavLinkWrapper = styled.a`
  display: inline-flex;
`;
export const NavigationFooterList = styled.ul`
  @media screen and (max-width: 1199px) {
    ${FLEX({ direction: "column" })};
    gap: 20px;
    /* margin-top: 20px; */
  }
  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    /* margin-top: 15px; */
  }
`;
export const NavigationListItem = styled.li`
  position: relative;
  ${FONT({ weight: "200", size: "16px", color: "#FFFFFF" })}
  line-height: 15px;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${COLORS.gold};
  }

  @media screen and (max-width: 1199px) {
    & a {
      ${FONT({ weight: "200", size: "16px", color: "white" })};
      line-height: 15px;
    }
  }
  @media screen and (min-width: 1200px)  {
    & a {
      ${FONT({ weight: "200", size: "16px", color: "white" })};
      line-height: 15px;
    }
  }
 
`;
export const FlexWrapperNavigation = styled.div`
  @media screen and (max-width: 1199px) {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 2px dashed rgba(149, 149, 149);
    border-top: 2px dashed rgba(149, 149, 149);
  }
  @media screen and (min-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
    /* border-right: 2px dashed rgba(149, 149, 149); */
    border-left: 2px dashed rgba(149, 149, 149);
  }
`;

export const TitleNavigationWrapper = styled.h3`
  ${FONT({ weight: "400", size: "16px", color: "white" })}
  line-height: 18px;
  text-align: center;
  @media screen and (min-width: 1200px) {
    ${FONT({ weight: "400", size: "16px", color: "white" })}
    line-height:15px;
    text-align: left;
  }
 
`;
export const FlexWrapperContacts = styled.div`
  @media screen and (max-width: 1199px) {
    ${FLEX({ direction: "column" })};
    gap: 20px;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 2px dashed rgba(149, 149, 149);
    text-align: center;
  }
  @media screen and (min-width: 1200px) {
    ${FLEX({
      direction: "column",
      justify: "flex-start",
      align: "flex-start",
    })};
    height: 88px;
    padding-left: 50px;
    padding-right: 50px;
    border-right: 2px dashed rgba(149, 149, 149);
    border-left: 2px dashed rgba(149, 149, 149);
  }
`;
export const LinkFooterContacts = styled.a`
  ${FONT({ weight: "200", size: "16px", color: "white" })};
  line-height: 15px;
  transition: all 0.3s linear;
  &:hover{
    color:${COLORS.gold};
  }
  @media screen and (min-width: 1200px) {
    ${FONT({ weight: "200", size: "16px", color: "white" })};
    margin-top: 15px;
  }
 
`;
export const TextBottomWrapper = styled.p`
  ${FONT({ weight: "400", size: "14px", color: "white" })}
  line-height: 15px;
 
`;

export const NavLinkPageFooterList = styled.ul`
  ${FLEX({})};
   @media screen and (max-width: 1199px) {
    width: 100%;
    margin-bottom:20px;
  }
`;
export const NavLinkPageFooterItem = styled.li`
justify-content:center;
  @media screen and (max-width: 1199px) {
    &:first-child {
      width:50%;
      padding-right: 20px;
      text-align:right;
    }
    &:last-child {
      width:50%;
      padding-left: 20px;
      border-left: 1px solid rgba(149, 149, 149);
    }
  }
  @media screen and (min-width: 1200px) {
    &:first-child {
      padding-right: 40px;
    }
    &:last-child {
      padding-left: 40px;
      border-left: 1px solid rgba(149, 149, 149);
    }
  }
`;
export const NavLinkPageFooter = styled(NavLink)`
  ${FONT({ weight: "200", size: "14px", color: "white" })};
  line-height: 15px;
  transition: all 0.5s linear;
  &:hover,
  &:focus {
    color: #ebaf16;
  }
`;

export const LogoFooter = styled.img`
  width: 100%;
  height: 100%;
`;
