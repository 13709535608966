import styled from "styled-components";
import bgProject from "../../assets/images/bg-project.png";
import bgFormImplement from "../../assets/images/bg-implement-form.png";
import { FLEX, FONT } from "../../utils";
import { Section, Title3 } from "../Styled";
import BgLine from "../../assets/images/border-img.png";

export const SectionImplement = styled(Section)`
position:relative;
padding-bottom:70px;
&::after{
    position:absolute;
    display:block;
    content:" ";
    width:100%;
    height:50px;
    bottom:-50px;
    left:0;
    background-image:url('${BgLine}');
};
`;

export const CardContextProject = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardProjectItemFirst = styled.div`
  ${FLEX({ direction: "column" })};
  gap: 40px;
  @media screen and (min-width: 1200px) {
    ${FLEX({ direction: "row-reverse" })};
    & > ${CardContextProject} {
      padding-right: 44px;
    }
  }
`;

export const FlexWrapperImage = styled.img`
  width: 100%;
`;
export const StyledCardListProject = styled.ul`
  ${FLEX({ direction: "column" })};
  gap: 40px;
`;

export const CardProjectItem = styled.li`
  ${FLEX({ direction: "column" })};
  gap: 40px;
  @media screen and (min-width: 1200px) {
    ${FLEX({ direction: "row-reverse" })};
    & > ${CardContextProject} {
      padding-right: 44px;
    }
  }
`;
export const CardItemImage = styled.div`
  @media screen and (min-width: 1200px) {
    min-width: 50%;
  }
`;
export const Image = styled.img`
  margin: 0;
`;

export const TitleCard = styled(Title3)`
  margin-bottom: 0;
`;
export const StyledFAIcon = styled.div`
  ${FLEX({})};
  gap: 15px;
`;
export const CardContextTop = styled.div`
  ${FLEX({ direction: "column", align: "flex-start" })};
  gap: 15px;
  margin-bottom: 25px;
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify: "space-between" })};
  }
`;

export const LinkProject = styled.a`
  display: inline-flex;
  ${FLEX({})};
  width: 100%;
  height: 35px;
  ${FONT({ weight: "400", size: "14px" })}
  line-height: 18px;
  color: #ebaf16;
  background: #ffffff;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  margin-top: 30px;
  transition: all 0.5s linear;
  &:hover,
  &:focus {
    color: #ffffff;
    border: 1px solid transparent;
    background: #ebaf16;
  }
  @media screen and (max-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) {
    ${FLEX({})};
    width: 196px;
    height: 35px;
  }
`;

export const ContactFormWrapper = styled.div`
${FLEX({ align: "flex-start" })};
width: 100%;
flex-direction:column;
background:#191722;
background-image:url('${bgFormImplement}');
padding:30px 20px;
@media screen and (min-width: 768px) {
align-items:center;
}
@media screen and (min-width: 1200px) {
  width: 100%;
  padding:30px 103px;
  align-items:flex-start;
}
`;

export const TextareaBoxWrapper = styled.div`
  ${FLEX({})};
  width: 311px;
  padding-top: 30px;
  margin-bottom: 30px;
  border-top: 2px dashed rgba(149, 149, 149);
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
`;

export const CardProjectItemSlider = styled.div`
 display: flex;
    flex-direction:column;
  gap: 40px;
  padding: 30px;
  background-color:#FFFAEF;
  background-image:url('${bgProject}');
  @media screen and (min-width: 1200px) {
     ${FLEX({ })};
    padding: 30px 50px;
  }
  `;

export const SwiperContainer = styled.div`
  position: relative;
  display: flex;
  overflow: auto;
`;
export const ChangeArrow = styled.button<{ name: string }>`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.name === "prev" ? "1px" : " ")};
  right: ${(props) => (props.name === "next" ? "1px" : " ")};
  width: 35px;
  height: 35px;
  stroke: #ebaf16;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
  z-index: 2;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;
