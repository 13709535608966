import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { ReactComponent as LeftIconArrow } from "../../assets/images/arrow-calendar-left.svg";
import { ReactComponent as RightIconArrow } from "../../assets/images/arrow-calendar-right.svg";
import {
  ChangeArrow,
  SpanTitleSwiper,
  SwiperContainer,
  SwiperTopWrapper,
} from "./styled";
import { SpanGold } from "../Styled";
import { Swiper as SwiperType, Navigation } from 'swiper';
import { FormSwiper } from "./FormSwiper";
import { useTranslation } from 'react-i18next';
import { TPropsSize } from "../../utils/types";


export const SwiperList: React.FC<TPropsSize> = ({ size }) => {
  const swiperRef = useRef<SwiperType>();
  const swiperRefTwo = useRef<SwiperType>();
  const { t } = useTranslation();
  
  const handlePrev = () => {
    swiperRef.current?.slidePrev();
    swiperRefTwo.current?.slidePrev();
  };
  const handleNext = () => {
    swiperRef.current?.slideNext();
    swiperRefTwo.current?.slideNext();
  }

  return (
    <SwiperContainer>
      <SwiperTopWrapper>
        <ChangeArrow className="prev" onClick={handlePrev}>
          <LeftIconArrow />
        </ChangeArrow>
        <Swiper
          initialSlide={1}
          allowTouchMove={false} 
          modules={[Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {size <= 1199 && (
            <>
              <SwiperSlide>
                <SpanTitleSwiper>
                  {t('investor-title-one')}<br />
                  <SpanGold>
                    {t('investor-title-gold')}
                  </SpanGold>
                </SpanTitleSwiper>
              </SwiperSlide>
              <SwiperSlide>
                <SpanTitleSwiper>
                  <SpanGold>
                    {t('application-title-form')} <br/>
                  </SpanGold>
                  {t('application-title-form-add')}
                </SpanTitleSwiper>
              </SwiperSlide>
              <SwiperSlide>
                <SpanTitleSwiper>
                  {t('project-title-form')}
                  <br />
                  <SpanGold>
                    {t('project-title-form-add')}
                  </SpanGold>
                </SpanTitleSwiper>
              </SwiperSlide>
            </>
          )}
          {size > 1199 && (
            <>
              <SwiperSlide>
                <SpanTitleSwiper>
                  {t('investor-title-one')}  <SpanGold> {t('investor-title-gold')}</SpanGold>
                </SpanTitleSwiper>
              </SwiperSlide>

              <SwiperSlide>
                <SpanTitleSwiper>
                  <SpanGold> {t('application-title-form')} </SpanGold> {t('application-title-form-add')}
                </SpanTitleSwiper>
              </SwiperSlide>
              <SwiperSlide>
                <SpanTitleSwiper>
                  {t('project-title-form')} <SpanGold> {t('project-title-form-add')}</SpanGold>
                </SpanTitleSwiper>
              </SwiperSlide>
            </>
          )}
        </Swiper>
        <ChangeArrow className="next" onClick={handleNext}>
          <RightIconArrow />
        </ChangeArrow>
      </SwiperTopWrapper>
      <Swiper
        initialSlide={1}
        allowTouchMove={false} 
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRefTwo.current = swiper;
        }}
      >
        <SwiperSlide>
          <FormSwiper className="contact" type={t("investor-title")}/>
        </SwiperSlide>
        <SwiperSlide>
          <FormSwiper className="application" type={t("application")} />
        </SwiperSlide>
        <SwiperSlide>
          <FormSwiper className="contact" type={t("project-form")} />
        </SwiperSlide>
      </Swiper>
    </SwiperContainer>
  );
};
