import React from "react";
import { Divider } from "../common/Divider";
import { Text, Title4 } from "../Styled";
import {
  StyledCardList,
  TextAdditionalWrapper,
  CardContext,
  CardItem,
  StyledFAIcon,
} from "./styled";
import { ReactComponent as IconOne } from "../../assets/images/inv5.svg";
import { ReactComponent as IconTwo } from "../../assets/images/inv1.svg";
import { ReactComponent as IconThree } from "../../assets/images/inv6.svg";
import { ReactComponent as IconFour } from "../../assets/images/inv4.svg";
import { useTranslation } from "react-i18next";

export const ListInvest: React.FC = () => {
  const { t } = useTranslation();
  const invests = [
    {
      id: 1,
      Icon: IconOne,
      title: t("invest-title1"),
      text: t("invest-text1"),
      add: t("invest-text1-add"),
    },
    {
      id: 2,
      Icon: IconTwo,
      title: t("invest-title2"),
      text: t("invest-text2"),
    },
    {
      id: 3,
      Icon: IconThree,
      title: t("invest-title3"),
      text: t("invest-text3"),
      add: t("invest-text3-add"),
    },
    {
      id: 4,
      Icon: IconFour,
      title: t("invest-title4"),
      text: t("invest-text4"),
    },
  ];

  return (
    <StyledCardList>
      {invests.map(({ Icon, ...invest }) => (
        <CardItem key={invest.id}>
          <StyledFAIcon>
            <Icon />
          </StyledFAIcon>
          <CardContext>
            <Title4>{invest.title}</Title4>
            <Text>{invest.text}</Text>
            {invest.add && (
              <>
                <Divider height={10} />
                <TextAdditionalWrapper>
                  <Text>{invest.add}</Text>
                </TextAdditionalWrapper>
              </>
            )}
          </CardContext>
        </CardItem>
      ))}
    </StyledCardList>
  );
};
