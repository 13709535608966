import React, { useState } from "react";
import { Calendar } from "../DataPicker/Calendar";
import { Container, SpanGold, Title2 } from "../Styled";
import { EventProjectWrapper, EventWrapper, SectionEvent } from "./styled";
import { DataChange } from "./DataChange";
import { Divider } from "../common/Divider";
import { ListEvent } from "./ListEvent";
import { ListEventProject } from "./ListEventProject";
import { TPropsSize } from "../../utils/types";
import { useTranslation } from 'react-i18next';

export const Events: React.FC<TPropsSize> = ({ size }) => {
  const [date, setDate] = useState<string>("10.02.2023");
  const { t } = useTranslation();

  return (
    <SectionEvent>
      <Container>
        <Title2 textAlign="center">
          {t('implement-title')}<SpanGold> {t('implement-title-gold')}</SpanGold>
          {t('event-title-add')}
        </Title2>
        <EventWrapper>
          <Calendar />
          <EventProjectWrapper>
            <ListEvent />
            {/* <DataChange newDate={date} /> */}
          </EventProjectWrapper>
        </EventWrapper>
        <Divider height={40} />
        <ListEventProject  size={size} />
        <Divider height={10} />
        {/* <DataChange newDate={date} /> */}
      </Container>
    </SectionEvent>
  );
};
