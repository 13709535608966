import React from 'react';
import { StyledForm } from './styled';
import {Form} from '../common/Form'

export type TClassFormProps= {
    className: string,
    type:string
}
export const FormSwiper: React.FC<TClassFormProps> = ({ className, type }) => {

    return (
        <StyledForm className={className}>
            <Form className={className} type={type} />
        </StyledForm>
    )
}