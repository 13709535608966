import styled from "styled-components";
import { FLEX, FONT } from "../../utils";
import { Button } from "../common/Button";
import { CardContextTop } from "../Implement/styled";
import { Section, Text } from "../Styled";
import BgLine from "../../assets/images/border-img.png";
import { Swiper } from "swiper/react";
import { ModalButton } from "../Modal/styled";

export const SectionEvent = styled(Section)`
position:relative;
padding-top:120px;
&::after{
    position:absolute;
    display:block;
    content:" ";
    width:100%;
    height:50px;
    bottom:-50px;
    left:0;
    background-image:url('${BgLine}');
};

`;
export const EventWrapper = styled.div`
  ${FLEX({ direction: "column" })};
  gap: 40px;
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify: "space-between", align: "flex-start" })};
  }
`;
export const StyledEventSwiper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 351px;
  @media screen and (min-width: 768px) {
    width: 744px;
  }
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
`;
export const StyledEventItemSwiper = styled.div`
  ${FLEX({ direction: "column-reverse" })};
  gap: 40px;
  width: 351px;
  @media screen and (min-width: 768px) {
    width: 744px;
  }
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify: "space-between" })};
    min-width: 748px;
  }
  /* @media screen and (min-width: 1400px) {
    ${FLEX({ justify: "space-between" })};
    min-width: 877px;
  } */
`;
export const StyledCardListProject = styled.ul`
  display: flex;
`;
export const StyledCardListItemProject = styled.li`
  ${FLEX({ direction: "column" })};
  gap: 40px;
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify: "space-between" })};
  }
`;

export const EventProjectWrapper = styled.div`
  width: 100%;
  ${FLEX({ direction: "column" })};
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
export const DateChangeWrapper = styled.div`
  @media screen and (max-width: 1199px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  ${FLEX({ justify: "flex-end" })};
  margin-top: 30px;
`;
export const DateChangeArrow = styled.button`
  width: 35px;
  height: 35px;
  stroke: #ebaf16;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
`;
export const DatePickerSelectorDate = styled.p`
  ${FONT({ weight: "200", size: "14px" })}
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 1200px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;
export const ImageEvent = styled.img`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 558px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 1200px) {
    width: 356px;
    height: 313px;
  }
`;
export const ImageEventProject = styled.img`
  width: 100%;
  @media screen and (min-width: 786px) {
    width: 558px;
    height: 340px;
  }
`;
export const CardImageEvent = styled.div`
  position: relative;

  @media screen and (min-width: 1200px) {
    display: flex;
    width: 100%;
  }
`;
export const CardItemImageEvent = styled.div`
  ${FLEX({})};
`;
export const StyledListEvent = styled.ul`
  ${FLEX({ direction: "column" })};
  gap: 40px;
`;
export const StyledCardItemProject = styled(StyledCardListItemProject)`
  padding-top: 40px;
  border-top: 2px dashed rgba(149, 149, 149);
`;
export const AddressWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
`;
export const AdressText = styled.p`
  ${FONT({ weight: "300", size: "14px" })}
  line-height: 18px;
  color: #959595;
  margin-left: 10px;
`;
export const CardContextTopEvent = styled(CardContextTop)`
  margin-bottom: 0;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 30px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;
export const ButtonAplication = styled(Button)`
  @media screen and (max-width: 767px) {
    max-width: ${(props) => props.maxWidth};
  }
`;
export const TextAdd = styled(Text)`
  margin-right: 5px;
  margin-left: 5px;
`;
export const ButtonChange = styled.button`
  width: 35px;
  height: 35px;
  stroke: #ebaf16;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
`;

export const WrapperNavigationSwiper = styled.div`
  ${FLEX({ justify: "space-between" })};
  @media screen and (min-width: 1200px) {
    position: absolute;
    display: flex;
    top: 95%;
    left: 50%;
    width: 179px;
    z-index: 2;
  }
  /* @media screen and (min-width: 1400px) {
    position: absolute;
    display: flex;
    top: 100%;
    left: 55%;
    width: 179px;
    z-index: 2;
  } */
`;

export const SwiperContainer = styled(Swiper)`
  width: 100%;
`;
export const SwiperDataContainer = styled(Swiper)`
  width: 109px;
`;


export const SwiperContainerInternal = styled(Swiper)`
  width: 100%;
`;



export const SwiperContainerImage = styled.div`
width: 351px;
@media screen and (min-width: 768px) {
  width:558px;
}
 @media screen and (min-width: 1200px) {
width:356px;
 }
`;

export const StyledModalImage = styled.div<{ className: string }>`
position: absolute;
top:0;
left:0;
display:${ props => props.className === "active" ? "flex" : "none"};
width: 100%;
height: 100%;
z-index:2;
`
export const ModalButtonImage = styled(ModalButton)`
position: absolute;
top:12px;
right: 12px;
background: white;
border-radius:4px;
z-index: 4;
`