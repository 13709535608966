import styled from "styled-components";
import { FLEX } from "../../utils";


export const FlexWrapperImage = styled.div`
display:relative;
  ${FLEX({})};
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
`;

