import React from "react";
import { Container, FlexWrapper } from "../Styled";
import { SocialListLink } from "./SocialListLink";
import {
  FooterWrapper,
  StyledFooter,
  TextBottomWrapper,
  NavLinkWrapper,
  LogoFooter,
} from "./styled";
import { FooterLinks } from "./FooterLinks";
import { ContactsFooterWrapper } from "./ContactsFooterWrapper";
import { Divider } from "../common/Divider";
import { FooterBottomLinkPages } from "./FooterBottomLinkPages";
import Logo from '../../assets/images/logo-footer.png'
import { TPropsSize } from "../../utils/types";


export const Footer: React.FC<TPropsSize> = ({ size }) => {
  return (
    <StyledFooter>
      <Container>
        <FooterWrapper>
          <NavLinkWrapper href="/" aria-label="logo">
            <LogoFooter src={Logo}  alt="logo"/>
          </NavLinkWrapper>
          <FooterLinks />
          <ContactsFooterWrapper />
          <SocialListLink />
        </FooterWrapper>
        <Divider height={40} />
        <FlexWrapper justify="space-between" direction={size <= 1199 ? "column-reverse" : "row"}>
          <TextBottomWrapper>© 2023 RHI Club</TextBottomWrapper>
          <FooterBottomLinkPages />
        </FlexWrapper>
      </Container>
    </StyledFooter>
  );
};
