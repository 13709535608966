import React, { useState } from 'react'

import { Container,  FlexWrapper, } from '../Styled'
import { FlexWrapperHero, HeroText, HeroTitle, HeroWrapper } from './styled';
import {ReactComponent as IconLogo} from '../../assets/images/logo-hero.svg'
import { Button } from '../common/Button';
import { Backdrop } from '../common/Backdrop';
import { Modal } from '../Modal/Modal';
import { useTranslation } from 'react-i18next';

export const Hero: React.FC = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);

  const handleClick = ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    document.body.classList.toggle('hidden');
    setActive(!active);
  }) 
    
  const handleClickImplement = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    window.location.href = "#implement";
  };
  const handleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    document.body.classList.toggle('hidden');
    setActive(!active);
    window.scrollTo(0, 0);
  };

  return (
    <HeroWrapper>
      <Container>
        <FlexWrapperHero>
          <IconLogo />
          <HeroText>
            {t('hero-text')}
          </HeroText>
         
          <FlexWrapper gap='24px'>
          <Button
            name='primary'
            type="button"
            title={t('to-investor')}
            width ='172px'
            height='40px'
              onClick={handleClick} />
            <Button
              name='secondary'
              type="button"
              title={t('to-projects')}
              width='172px'
              height='40px'
              onClick={handleClickImplement}
            />
          </FlexWrapper>
          <Backdrop
            className={active ? 'active' : ''}
          >
            <Modal active={active}
              handleClick={handleModal}
              setActive={setActive}
              type={t('investor-title')}
            />
          </Backdrop>
        </FlexWrapperHero>
      </Container>
    </HeroWrapper>
  )
}

