import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'

export const Container = styled.div`
    width: 100%;
    padding: 0 12px;
    margin: 0 auto;

  @media screen and (min-width: 375px) {
    max-width: 375px;
  }
  @media screen and (min-width: 768px) {
    max-width: 768px;
  }
   @media screen and (min-width: 1200px) {
    max-width: 1200px;
    padding: 0 24px;
  }
  /* @media screen and (min-width: 1400px) {
    max-width: 1400px;
    padding: 0 64px;
  } */
`

export const Section = styled.section<{
  color?: string
}>`
  padding-top: 70px;
  padding-bottom: 40px;
  background: ${props => props.color};
 
`

export const FlexWrapper = styled.div<{
  display?:string,
  direction?: string,
  justify?: string,
  gap?: string,
  align?: string,
  width?:string
}>`
display: ${props => props.display};
  ${FLEX({})};
  flex-direction: ${props => props.direction};
  justify-content:${props => props.justify};
  align-items:${props => props.align};
  gap: ${props => props.gap};
  width:${props => props.width};
`
export const Title2 = styled.h2<{
  textAlign?: string
}>`
  ${FONT({ weight: '200', size: '32px' })};
  line-height: 40px;
  text-align:${props => props.textAlign};
  margin-bottom:40px;
  @media screen and (min-width: 1200px) {
    ${FONT({ weight: '300', size: '32px' })};
    line-height: 40px;
  }
 
`
export const Title3 = styled.h3<{
  textAlign?: string,
  color?: string,
  marginBottom?:string,
}>`
  ${FONT({ weight: '200', size: '24px' })};
  font-style: normal;
  color:${props => props.color};
  line-height: 30px;
  text-align:${props => props.textAlign};
  margin-bottom:${props => props.marginBottom ? `${props.marginBottom}`: "25px"};
`
export const Text = styled.p<{
  size?: string,
  lineHeight?:string
}>`
${FONT({ weight: '200', size: '14px' })};
line-height:18px;
font-size:${props => props.size};
line-height: ${props => props.lineHeight};
`
export const SpanGold = styled.span`
color:#EBAF16;
`
export const Title4 = styled.h4`
${FONT({ weight: "300", size:'16px' })};
line-height:18px;
margin-bottom:5px;
`

export const Image = styled.img`
width: 100%;
`

export const SectionAdd = styled(Section)`
padding-top:120px;
`
export const LinkProject = styled.a<{
  className: string,
  marginTop?:string,
}>`
  display: inline-flex;
  ${FLEX({})};
  width: 100%;
  height: 35px;
  ${FONT({ weight: "400", size: "14px" })}
  line-height: 18px;
  color: ${(props) => (props.className === "primary" ? "#ebaf16" : "white")};
  background: ${(props) => (props.className === "primary" ? "white" : "#ebaf16")};
  border: 1px solid;
  border-color: ${(props) => (props.className === "primary" ? "#ebaf16" : "transparent")};
  border-radius: 6px;
  margin-top: ${(props) => props.marginTop};
  transition: all 0.5s linear;
  &:hover,
  &:focus {
    color: ${(props) => (props.className === "primary" ? "white" : "#ebaf16")};
    border-color: ${(props) => (props.className === "primary" ? "transparent" : "#ebaf16")};;
    background: ${(props) => (props.className === "primary" ? "#ebaf16" : "white")};;
  }
  @media screen and (max-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) {
    ${FLEX({})};
    width: 196px;
    height: 35px;
  }
`;