import React, { useEffect, useState } from 'react';
import closeBtn from "../../assets/images/close.png";
import { CloseIcon } from '../Modal/styled';
import { CardImageEvent, ImageEvent, ModalButtonImage, StyledModalImage } from './styled';

export type TModalImageProps = {
    largeImageURL: string;
    onClose: any;
    showModal: boolean;
}

export const ModalImage: React.FC<TModalImageProps> = ({ largeImageURL, onClose, showModal }) => {
    const [close, setClose] = useState<boolean>(false);

    const handleClick = () => {
        setClose(!close);
        onClose();
    }

    return (
        <StyledModalImage  className={showModal ? "active" : " "}>
            <ModalButtonImage onClick={handleClick}>
                <CloseIcon src={closeBtn} alt="close icon" />
            </ModalButtonImage>
            <CardImageEvent>
                <ImageEvent src={require(`../../assets/images/${largeImageURL}`)} alt="image" />
            </CardImageEvent>
        </StyledModalImage>
        
    )
}