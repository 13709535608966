import React from "react";
import axios from "axios";
import { Formik } from "formik";
import { InputField } from "../common/Input/InputField";
import { TFormModalProps, TModalProps } from "./types";
import close from "../../assets/images/close.png";
import {
  InputBoxWraper,
  ModalButton,
  ModalContent,
  TitleForm,
  FormModal,
  CloseIcon,
  ButtonSubmit,
} from "./styled";
import { FlexWrapper, SpanGold } from "../Styled";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const initialValues: TFormModalProps = {
  name: "",
  company: "",
  phone: "",
  email: "",
};

export const Modal: React.FC<TModalProps> = ({
  active,
  handleClick,
  setActive,
  type,
}) => {
  const { t } = useTranslation();
  const ContactSchema = Yup.object({
    name: Yup.string().min(2).required(t("field-required")),
    company: Yup.string().min(2).required(t("field-required")),
    phone: Yup.string()
      .required(t("field-required"))
      .matches(phoneRegExp, t("invalid-phone"))
      .min(5)
      .max(20),
    email: Yup.string().email(t("invalid-email")).required(t("field-required")),
  });

  const onSubmitData = (values: any, { resetForm }: { resetForm: any }) => {
    const data = {
      name: values.name,
      company: values.company,
      phone: values.phone,
      email: values.email,
      type: `${type}`,
    };

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("company", data.company);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("type", data.type);

    axios({
      method: "post",
      url: "https://richhouseinvest.com/php/mail.php",
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      data: formData,
    })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
    resetForm({ values: initialValues });
    setActive(!active);
  };

  return (
    <ModalContent className={active ? "active " : ""}>
      <FlexWrapper justify="space-between">
        <TitleForm>
          {t("investor-title")}
          
        </TitleForm>
        <ModalButton onClick={handleClick}>
          <CloseIcon src={close} alt="close icon" />
        </ModalButton>
      </FlexWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={ContactSchema}
        onSubmit={onSubmitData}
      >
        {({ values, handleChange, handleSubmit }) => (
          <FormModal onSubmit={handleSubmit}>
            <InputBoxWraper>
              <InputField
                type="text"
                name="name"
                label={t("name")}
                onChange={handleChange}
                value={values.name}
                className="modalInput"
              />
              <InputField
                type="text"
                name="company"
                label={t("company")}
                onChange={handleChange}
                value={values.company}
                className="modalInput"
              />
              <InputField
                type="tel"
                name="phone"
                label={t("phone")}
                onChange={handleChange}
                value={values.phone}
                className="modalInput"
              />
              <InputField
                type="email"
                name="email"
                label="Email"
                onChange={handleChange}
                value={values.email}
                className="modalInput"
              />
            </InputBoxWraper>

            <ButtonSubmit
              type="submit"
              name="primary"
              title={t("investor-title")}
              width="261px"
              height="35px"
            />
          </FormModal>
        )}
      </Formik>
    </ModalContent>
  );
};
