import styled from "styled-components";
import { FLEX, FONT } from "../../utils";
import bgForm from '../../assets/images/bg-form.png';
import bgCard from '../../assets/images/bg-card.png';


export const ContactFormWrapper = styled.div`
${FLEX({align:"flex-start"})};
width: 100%;
flex-direction:column;
background-image:url('${bgForm }');
padding:30px 20px;
@media screen and (min-width: 768px)and (max-width: 1199px) {
align-items:center;
}
@media screen and (min-width: 1200px) {
padding:30px 103px;
}
`
export const StyledContactForm = styled.div`
${FLEX({direction:"column"})};
width: 100%;
height: auto;
`
export const FormContact = styled.form`
width: 100%;
${FLEX({ direction: "column" })};
`
export const InputBoxWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;
margin-bottom:30px;
  @media screen and (min-width: 1200px) {
    width: 100%;
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr 1fr;
    grid-row-gap:20px;
    grid-column-gap:30px
  }
`
export const StyledCardList = styled.div`
 ${FLEX({direction:'column'})}
  gap:40px;
  margin-bottom: 40px;
  justify-content: space-between;
    @media screen and (min-width: 768px) {
      ${FLEX({})}
      flex-wrap: wrap;
    }
  @media screen and (min-width: 1200px) {
    ${FLEX({ justify:"space-between" })}
  }
`;
export const TextAdditionalWrapper = styled.div`
width:100%;
padding-top:10px;
border-top:2px dashed rgba(149, 149, 149);
`
export const CardItem = styled.div`
display: flex;
flex-direction: column;
width:100%;
height:286px;
padding:20px 20px 25px 20px;
background:#FFFAEF;
&:nth-child(2n){
  background-image:url('${bgCard }');
}
 @media screen and (min-width: 768px) {
  width:258px;
 }
`

export const StyledFAIcon = styled.div`
${FLEX({})};
width:60px;
height:60px;
padding:10px;
margin-bottom: 10px;
background: #FFF1D1;
`
export const CardContext = styled.div`
${FLEX({direction:"column",align:"flex-start"})};
`
export const StyledNumberList = styled.ul`
${FLEX({ direction: 'column' })};
gap:40px;
padding:40px 20px;
border-top:2px dashed rgba(149, 149, 149);
border-bottom:2px dashed rgba(149, 149, 149);
background:#FFFAEF;
 @media screen and (min-width: 1200px) {
  ${FLEX({ justify: 'space-between' })};
 }
`
export const NumberItem = styled.li`
${FLEX({direction:'column'})};
gap: 10px;
`
export const NumberWrapper = styled.span`
${FONT({ weight: '300', size: '32px', color: '#EBAF16' })};
line-height:65px;
@media screen and (min-width: 1200px) {
 ${FONT({ weight: '300', size: '24px', color: '#EBAF16' })}; 
 
}

`