import React from 'react';
import { LinkFooterContacts, TitleNavigationWrapper, FlexWrapperContacts } from './styled';
import { useTranslation } from 'react-i18next';

export const ContactsFooterWrapper: React.FC = () => {
    const { t } = useTranslation();
    return (
        <FlexWrapperContacts >
            <TitleNavigationWrapper> {t('contacts')}:</TitleNavigationWrapper>
            <LinkFooterContacts href="tel:+971502151991" target="_blank" aria-label='tel'>+971502151991</LinkFooterContacts>
            <LinkFooterContacts href="mailto:rh@richmanhouse.com" target="_blank" aria-label='email'>rh@richmanhouse.com</LinkFooterContacts>
        </FlexWrapperContacts>
    )
}