import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
  NavLinkPageFooter,
  NavLinkPageFooterItem,
  NavLinkPageFooterList,
} from "./styled";

export const FooterBottomLinkPages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NavLinkPageFooterList>
      <NavLinkPageFooterItem key={uuidv4()}>
        <NavLinkPageFooter to="terms" aria-label="link" target="_blank">
          {t('terms-use')}
        </NavLinkPageFooter>
      </NavLinkPageFooterItem>
      <NavLinkPageFooterItem key={uuidv4()}>
        <NavLinkPageFooter to="privacy" aria-label="link" target="_blank">
          {t('privacy-policy')}
        </NavLinkPageFooter>
      </NavLinkPageFooterItem>
    </NavLinkPageFooterList>
  );
};
