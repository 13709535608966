import styled, { keyframes } from "styled-components";
import { FLEX, FONT } from "../../utils";
// import { ReactComponent as Logo } from "../../assets/images/logo.svg"
import { COLORS } from "../../assets";
import { Link, animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/images/arrow-select.png";

export const HeaderWrapper = styled.header`
  position: absolute;
  display: flex;
  /* background: transparent; */
  background: ${COLORS.white};
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  z-index: 100;
`;

export const LogoIcon = styled.img`
  width: 100%;
  height: 100%;
`;
const AnimationSideIn = keyframes`
  0% { top: -80px; }
  100% { top: 0; }
`;

const AnimationSideOut = keyframes`
  0% { top: 0; }
  100% { top: -80px; }
`;

export const BackdropMenuMobWrapper = styled.div<{ className: string }>`
  @media screen and (max-width: 1199px) {
    position: fixed;
    width: 100vw;
    top: 80px;
    left: 0;
    height: calc(100vh - 80px);
    display: ${(props) => (props.className === "is-open" ? "flex" : "none")};
    visibility: ${(props) =>
      props.className === "is-open" ? "visible" : "hidden"};
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
`;
export const StyledMenuLink = styled.div<{ className: string }>`
  justify-content: flex-start;
  @media screen and (max-width: 1199px) {
    position: absolute;
    display: ${(props) => (props.className === "is-open" ? "flex" : "none")};
    flex-direction: column;
    width: 242px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1000;
    background: ${COLORS.white};
    opacity: ${(props) => (props.className === "is-open" ? 1 : 0)};
    visibility: ${(props) =>
      props.className === "is-open" ? "visible" : "hidden"};
    text-align: center;
    padding: 30px 30px 0 30px;
    box-shadow: 0 1px 3px #ebaf16;
    transition: all 1000s linear;
    animation: ${(props) =>
        props.className === "is-open" ? AnimationSideIn : AnimationSideOut}
      0.5s ease -in -out 0s 1;

    @media screen and (min-width: 1200px) {
      ${FLEX({})}
    }
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1199px) {
    ${FLEX({ direction: "column", align: "flex-start" })}
    gap: 20px;
  }
`;
export const NavigationListItem = styled.li`
  @media screen and (min-width: 1200px) {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

export const NavLinkWrapper = styled(NavLink)`
  display: inline-flex;
  @media screen and (max-width: 1199px) {
    margin-right: auto;
  }
`;
export const NavLinkPage = styled(NavLink)`
  text-decoration: none;
  ${FONT({ weight: "200", size: "16px" })}
  line-height: 15px;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${COLORS.gold};
  }
  &:active {
    color: ${COLORS.gold};
    border-color: ${COLORS.gold};
  }
  @media screen and (max-width: 1199px) {
    ${FONT({ weight: "200", size: "16px" })};
  }
`;
export const LinkContacts = styled.a`
  text-decoration:none;
  ${FONT({ weight: "200", size: "16px" })};
`;
export const SelectWrapper = styled.select`
  width: 52px;
  height: 20px;
  ${FONT({ weight: "200", size: "16px" })}
  border: none;
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  outline: 0 !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  appearance: none;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: left 25px top 50%, 0 0;
  background-size: auto, 100%;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${COLORS.white};
    text-decoration: none;
    outline: none;
  }
  @media screen and (max-width: 1199px) {
    margin-right: 30px;
  }
`;
export const Option = styled.option`
  width: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
`;
export const StyledContacts = styled.div`
  ${FLEX({ direction: "column", align: "flex-start" })};
  gap: 20px;
  @media screen and (max-width: 1199px) {
    padding-top: 30px;
    gap: 40px;
  }
`;

export const ButtonMenu = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease-out;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;
export const NavLinkAuthorization = styled(NavLink)<{ className?: string }>`
  text-decoration: none;
  ${FONT({ weight: "200", size: "16px" })};
  padding-left: ${(props) => (props.className === "first" ? "0" : "20px")};
  padding-right: ${(props) => (props.className === "first" ? "20px" : "0")};
  border-right: ${(props) =>
    props.className === "first" ? " 0.5px solid #000000;" : "none"};
  margin-left: ${(props) => (props.className === "first" ? "8px" : "0")};
  transition: all 0.3s linear;
  &:hover {
    color: ${COLORS.gold};
  }
  @media screen and (max-width: 1199px) {
    ${FONT({ weight: "200", size: "16px", color: "#000000" })};
    padding-left: ${(props) => (props.className === "first" ? "0" : "10px")};
    padding-right: ${(props) => (props.className === "first" ? "10px" : "0")};
  }
`;
export const StyledAuthorizationWrapper = styled.div`
  ${FLEX({ justify: "space-between" })};
  @media screen and (max-width: 1199px) {
    padding-bottom: 30px;
    border-bottom: 2px dashed rgba(149, 149, 149);
  }
`;
export const FlexWrapperNavigation = styled.div`
  ${FLEX({ justify: "space-between" })};
  gap: 62px;
  @media screen and (max-width: 1199px) {
    ${FLEX({ direction: "column", align: "flex-start" })};
    gap: 20px;
    padding-bottom: 30px;
    border-bottom: 2px dashed rgba(149, 149, 149);
  }
`;
export const FlexWrapperNavLinkPage = styled.div`
  ${FLEX({ direction: "column", align: "flex-start" })};
  gap: 20px;
  @media screen and (min-width: 1199px) {
    ${FLEX({})};
  }
`;

export const StyledHashLink = styled(Link).attrs(() => ({
  activeClass: "active",
}))`
  position: relative;
  text-decoration: none;
  ${FONT({ weight: "200", size: "16px" })};
  line-height: 15px;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${COLORS.gold};
  }
  &::before {
    content: "";
    bottom: 0;
    right: 0;
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: ${COLORS.gold};;
    transition: 0.5s linear;
  }
  &:hover::before {
    width: 100%;
    left: 0;
  }
  @media screen and (max-width: 1199px) {
    ${FONT({ weight: "200", size: "16px" })};
    line-height: 20px;
  }
`;
