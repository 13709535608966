import React, { FC } from 'react';
import { Label, StyledInputWrapper, Error, StyledInput } from './styled';
import { TInputProps } from './types';

export const Input: FC<TInputProps> = ({
    name,
    label,
    isInvalid,
    errorText,
    value,
    type,
    className = 'contact',
    ...props
}) => {
    return (
        <StyledInputWrapper>
            <StyledInput
                autoComplete="off"
                name={name}
                type={type}
                value={value}
                placeholder=" "
                className={className}
                {...props}
            />
            {label && (
                <Label className="contact" htmlFor={name}>
                    {label}
                </Label>
            )}

            {isInvalid && errorText && <Error>{errorText}</Error>}
        </StyledInputWrapper>
    );
};
