import styled from "styled-components";
import { FLEX, FONT } from "../../utils";
import bgModal from "../../assets/images/bg-modal.png";
import bgFormImplement from "../../assets/images/bg-implement-form.png";
import { Swiper } from "swiper/react";

export const SwiperContainer = styled.div`
  position: relative;
  @media screen and (min-width: 1200px) {
    padding: 0 74px;
  }
`;
export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 342px;
  margin-bottom: 70px;
`;

export const StyledSwiperList = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ChangeArrow = styled.button`
  width: 35px;
  height: 35px;
  stroke: #ebaf16;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
`;
export const SpanTitleSwiper = styled.h2`
  ${FONT({ weight: "300", size: "32px" })}
  color: #000000;
  line-height: 40px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledForm = styled.div<{
  className?: string;
}>`
  padding: 30px 20px;
  background: ${(props) =>
    props.className === "application" ? "#FFFAEF" : "#191722"};
  background-image: ${(props) =>
    props.className === "application"
      ? `url('${bgModal}')`
      : `url('${bgFormImplement}')`};
  opacity: 1;
  transition: 0.3s linear all;

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    ${FLEX({ direction: "column" })}
  }

  @media screen and (min-width: 1200px) {
    padding: ${(props) =>
      props.className === "application" ? "30px 40px" : "30px"};
  }
`;

export const SwiperTopWrapper = styled.div`
  ${FLEX({ justify: "space-between" })};
  margin-bottom: 40px;
  @media screen and (min-width: 1200px) {
    padding: 0 30px;
  }
`;
export const ChangeArrowSlider = styled.button<{ name: string }>`
  position: absolute;
  top: 40%;
  left: ${(props) => (props.name === "prev" ? "-20px" : " ")};
  right: ${(props) => (props.name === "next" ? "-20px" : " ")};
  width: 35px;
  height: 35px;
  stroke: #ebaf16;
  border: 1px solid #ebaf16;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
  z-index: 10;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const CardItemImage = styled.div`
  width: 100%;
  height: 100%;
`;
export const SwiperWrapper = styled(Swiper)`
  width: 100%;
  height: 272px;
`;
